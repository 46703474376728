import React, {Fragment} from 'react'
import Header from "../components/Header";
import ContactPage from "../templates/Contact-ayudamos";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import SectionTitle from '../components/UI/SectionTitle/index'
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import BlogDetails from '../templates/BlogDetails/ContentWrapper'
const Transferencia = () => {
  return (
    <div>
            <Fragment>
            <Header/>
            <div className="container" style={{marginBottom:'60px', marginTop:'60px'}}>
              <SectionTitle title="" heading="Datos de transferencia bancaria" />
              <div style={{textAlign:'center'}}>
                <h3 >HSBC</h3>
                <p>Cuenta: 4040495079 </p>
                <p>Clabe: 021910040404950791 </p>
                <p>No. de cliente:: 13681923 </p>
                <br/>
                <h3 >Oxxo</h3>
                <p>No. de tarjeta: 4766 8414 2437 8696</p>
              </div>
              

            </div>
            <CallToAction/>
            <Footer/>
            <LoginRegister/>
            <MobileMenu/>
        </Fragment>
    </div>
  )
}

export default Transferencia
